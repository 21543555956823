import React, { useState } from "react";

import SetupGame from "../SetupGame/";
import Scoreboard from "../Scoreboard/";

const GameFrame = () => {
  const [playerCount, setPlayerCount] = useState(2);
  const [totalRounds, setTotalRounds] = useState(18);
  const [players, setPlayers] = useState([]);
  const [gameActive, setGameActive] = useState(false);

  const setupPlayerCount = (gamePlayerCount) => {
    setPlayerCount(gamePlayerCount);
  };

  const setupTotalRounds = (gameTotalRounds) => {
    setTotalRounds(gameTotalRounds);
  };

  const setupPlayers = (gamePlayers) => {
    setPlayers(gamePlayers);
  };

  const isGameActive = (state) => {
    setGameActive(state);
  };

  return (
    <>
      {gameActive ? (
        <Scoreboard
          setupPlayerCount={playerCount}
          setupTotalRounds={totalRounds}
          setupPlayers={players}
        />
      ) : (
        <SetupGame
          setupPlayerCount={setupPlayerCount}
          setupTotalRounds={setupTotalRounds}
          setupPlayers={setupPlayers}
          isGameActive={isGameActive}
        />
      )}
    </>
  );
};

export default GameFrame;
