import React from "react";

import "./Header.sass";

const Header = () => {
  const setupNewGame = () =>
    document.getElementById("setup-game").classList.toggle("hide");

  return (
    <header>
      <h1>
        <a href="/" title="Golf Card Game">
          Golf
        </a>
      </h1>
    </header>
  );
};

export default Header;
