import React, { useState } from "react";

const SetupGame = ({
  isGameActive,
  setupPlayerCount,
  setupTotalRounds,
  setupPlayers,
}) => {
  const [playerCount, setPlayerCount] = useState(2);
  const [totalRounds, setTotalRounds] = useState(18);
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState();

  const incrementChange = (direction, value) =>
    direction === "add" ? Number(value + 1) : Number(value - 1);

  const stepDecrease = () => {
    if (playerCount >= 3) {
      setPlayerCount(incrementChange("minus", playerCount));
    }
  };

  const stepIncrease = () => {
    if (playerCount <= 5) {
      setPlayerCount(incrementChange("add", playerCount));
    }
  };

  const setupRoundsSelect = () => {
    const roundsSelect = [];
    for (let option = 1; option < 19; option++) {
      roundsSelect.push(
        <option key={option} value={option}>
          {option}
        </option>
      );
    }
    return roundsSelect;
  };

  const startGame = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    setTotalRounds(formJson.total_rounds);
    delete formJson.player_count;
    delete formJson.total_rounds;

    const convertFormDataToArray = Object.values(formJson);
    setPlayers(convertFormDataToArray);

    setupPlayerCount(playerCount);
    setupTotalRounds(totalRounds);
    setupPlayers(convertFormDataToArray);
    isGameActive(true);
  };

  return (
    <form id="setup-game" method="post" onSubmit={startGame}>
      <div className="field-group">
        <fieldset>
          <h2>How many players?</h2>
          <button id="step-dec" type="button" onClick={() => stepDecrease()}>
            -
          </button>
          <input
            id="step-val"
            name="player_count"
            type="number"
            value={playerCount}
            min="2"
            max="6"
            onChange={(count) => setPlayerCount(count)}
            disabled
          />
          <button id="step-inc" type="button" onClick={() => stepIncrease()}>
            +
          </button>
        </fieldset>

        <fieldset id="player-count">
          <h2>Enter Player Details</h2>
          {Array(playerCount)
            .fill(null)
            .map((value, index) => {
              return (
                <input
                  key={index + 1}
                  placeholder={`Player ${index + 1}`}
                  id={`player-${index + 1}`}
                  name={`player_${index + 1}`}
                  type="text"
                  required
                />
              );
            })}
        </fieldset>

        <fieldset>
          <h2>How many rounds?</h2>
          <select
            id="total-rounds"
            name="total_rounds"
            defaultValue={18}
            onChange={(e) => setTotalRounds(e.target.value)}
          >
            {setupRoundsSelect()}
          </select>
        </fieldset>

        <fieldset>
          <button id="start-game" type="submit">
            Start Game
          </button>
          {error ? <p id="error">{error}</p> : null}
        </fieldset>

        {players}
      </div>
    </form>
  );
};

export default SetupGame;
