import React, { useState } from "react";

const Scoreboard = ({ setupPlayerCount, setupTotalRounds, setupPlayers }) => {
  const [playerCount, setPlayerCount] = useState(2);
  const [totalRounds, setTotalRounds] = useState(18);
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState();

  const finishGame = (e) => {
    console.log("Game Finished, saving...");
  };

  const setupEntryTable = (gamesToPlay, playerCount) => {
    const scoreEntryRows = [];
    for (let row = 0; row < gamesToPlay; row++) {
      const scoreEntryInputs = [];
      for (let column = 0; column < playerCount; column++) {
        scoreEntryInputs.push(
          <td key={column}>
            <input
              type="tel"
              id={`player-${column}-row-${row}`}
              className={`player-${column}`}
              onInput={() => calculateScores()}
              placeholder={row + 1}
              max="60"
            />
          </td>
        );
      }

      scoreEntryRows.push(<tr key={row}>{scoreEntryInputs}</tr>);
    }
    return scoreEntryRows;
  };

  const calculateScores = () => {
    setupPlayers.map((player, index) => {
      let playerScore = 0;
      const currentPlayerInputs = document.querySelectorAll(
        `input.player-${index}`
      );

      currentPlayerInputs.forEach((e) => {
        if (!isNaN(e.value) && e.value <= 60) {
          document.getElementById(e.id).classList.remove("error");
          playerScore = playerScore + Number(e.value);
        } else {
          document.getElementById(e.id).classList.add("error");
        }
      });

      document.getElementById(`output-player-${player}`).value = playerScore;

      checkLowestScorer();
    });
  };

  const checkLowestScorer = () => {
    const getPlayerScores = document.querySelectorAll(".live-score-output");

    const allScores = [];
    getPlayerScores.forEach((e) => {
      removeWinner(e.id);
      if (e.value) {
        allScores.push({
          score: e.value,
          element: e.id,
        });
      }
    });

    allScores.sort((a, b) => a.score - b.score);

    liveWinner(allScores[0].element);
  };

  const liveWinner = (input) => {
    document.getElementById(input).classList.toggle("win");
  };

  const removeWinner = (input) => {
    document.getElementById(input).classList.remove("win");
  };

  return (
    <form id="game" method="post" onSubmit={finishGame}>
      <div className="player-names">
        <table id="player-names">
          <thead>
            <tr>
              {setupPlayers.map((player) => {
                return <th key={player}>{player}</th>;
              })}
            </tr>
          </thead>
          <tbody>{setupEntryTable(setupTotalRounds, setupPlayerCount)}</tbody>
        </table>
      </div>

      <div id="live-scores">
        <ul>
          {setupPlayers.map((player) => {
            return (
              <li key={player}>
                <input
                  type="number"
                  id={`output-player-${player}`}
                  className="live-score-output"
                  defaultValue={"0"}
                  disabled
                />
              </li>
            );
          })}
        </ul>
      </div>

      {/* <fieldset>
        <button id="save-game" type="submit">
          Save Game
        </button>
        {error ? <p id="error">{error}</p> : null}
      </fieldset> */}
    </form>
  );
};

export default Scoreboard;
