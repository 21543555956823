import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout.jsx";
import NoRoute from "./pages/NoRoute.jsx";
import Game from "./pages/Game.jsx";

const container = document.getElementById("game-frame");
const root = createRoot(container);

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Game />} />
          <Route path="*" element={<NoRoute />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

root.render(<App />);
