import React from "react";

import "../components/Game/Game.sass";
import GameFrame from "../components/Game";

const Game = () => {
  return (
    <>
      <div className="canvas">
        <GameFrame />
      </div>
    </>
  );
};

export default Game;
