import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../components/Header";

const Layout = () => {
  return (
    <>
      <Header />
      {/* <div className="canvas"> */}
      <Outlet />
      {/* </div> */}
    </>
  );
};

export default Layout;
